var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.show}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[(_vm.editable)?_c('h4',{staticClass:"text-primary"},[_vm._v(" Información de Centro de Mando P&M ")]):_vm._e(),(!_vm.editable)?_c('h4',{staticClass:"text-primary"},[_vm._v(" Formulario de Administrador Centros de Mando P&M ")]):_vm._e(),_c('hr'),_c('br')]),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Poste ")]),_c('validation-provider',{attrs:{"name":"Poste","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.poste),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "poste", $$v)},expression:"adminGabinete.poste"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"7"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Dirección ")]),_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.direccion),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "direccion", $$v)},expression:"adminGabinete.direccion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Zona ")]),_c('validation-provider',{attrs:{"name":"Zona","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"options":_vm.zonas,"label":"nombre","state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.zona),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "zona", $$v)},expression:"adminGabinete.zona"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Ubicación ")]),_c('validation-provider',{attrs:{"name":"Ubicación","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"no-resize":"","disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.ubicacion),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "ubicacion", $$v)},expression:"adminGabinete.ubicacion"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Cantidad de Reflectores ")]),_c('validation-provider',{attrs:{"name":"Cantidad de Reflectores","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.reflectores),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "reflectores", $$v)},expression:"adminGabinete.reflectores"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Flip-pon ")]),_c('validation-provider',{attrs:{"name":"Flip-pon","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.flippon),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "flippon", $$v)},expression:"adminGabinete.flippon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Cantidad de Borneras ")]),_c('validation-provider',{attrs:{"name":"Cantidad de Borneras","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.borneras),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "borneras", $$v)},expression:"adminGabinete.borneras"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Luz Piloto ")]),_c('validation-provider',{attrs:{"name":"Luz Piloto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesSiNoYa,"label":"nombre"},model:{value:(_vm.adminGabinete.luzPiloto),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "luzPiloto", $$v)},expression:"adminGabinete.luzPiloto"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Protección Transitoria ")]),_c('validation-provider',{attrs:{"name":"Protección Transitoria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesSiNoYa,"label":"nombre"},model:{value:(_vm.adminGabinete.transitoria),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "transitoria", $$v)},expression:"adminGabinete.transitoria"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Horario Timer ")]),_c('validation-provider',{attrs:{"name":"Horario Timer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesTimer,"label":"nombre"},model:{value:(_vm.adminGabinete.horaTimer),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "horaTimer", $$v)},expression:"adminGabinete.horaTimer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1)?_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Inicio del Timer ")]),_c('validation-provider',{attrs:{"name":"Inicio del Timer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"placeholder":"Inicio del Timer","label-no-time-selected":"Inicio del Timer","close-button-variant":"primary","label-close-button":"Aceptar","locale":"es","disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.inicioTimer),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "inicioTimer", $$v)},expression:"adminGabinete.inicioTimer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,584896601)})],1)],1):_vm._e(),(_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1)?_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Fin del Timer ")]),_c('validation-provider',{attrs:{"name":"Fin del Timer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{attrs:{"placeholder":"Fin del Timer","label-no-time-selected":"Fin del Timer","close-button-variant":"primary","label-close-button":"Aceptar","locale":"es","disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.finTimer),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "finTimer", $$v)},expression:"adminGabinete.finTimer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1007752979)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Candado ")]),_c('validation-provider',{attrs:{"name":"Candado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesCandado,"label":"nombre"},model:{value:(_vm.adminGabinete.candado),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "candado", $$v)},expression:"adminGabinete.candado"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Caja Socket ")]),_c('validation-provider',{attrs:{"name":"Caja Socket","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesSiNo,"label":"nombre"},model:{value:(_vm.adminGabinete.socket),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "socket", $$v)},expression:"adminGabinete.socket"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Contador ")]),_c('validation-provider',{attrs:{"name":"Contador","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesSiNo,"label":"nombre"},model:{value:(_vm.adminGabinete.contador),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "contador", $$v)},expression:"adminGabinete.contador"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Tierra Física ")]),_c('validation-provider',{attrs:{"name":"Tierra Física","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesSiNo,"label":"nombre"},model:{value:(_vm.adminGabinete.tierraFisica),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "tierraFisica", $$v)},expression:"adminGabinete.tierraFisica"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":_vm.adminGabinete.horaTimer && _vm.adminGabinete.horaTimer.id === 1 ? 3:4}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Sticker ")]),_c('validation-provider',{attrs:{"name":"Sticker","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.editable,"state":errors.length > 0 ? false:null,"options":_vm.opcionesSiNo,"label":"nombre"},model:{value:(_vm.adminGabinete.sticker),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "sticker", $$v)},expression:"adminGabinete.sticker"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Observaciones ")]),_c('validation-provider',{attrs:{"name":"Observaciones","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"no-resize":"","disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.observaciones),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "observaciones", $$v)},expression:"adminGabinete.observaciones"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Falta ")]),_c('validation-provider',{attrs:{"name":"Falta","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"no-resize":"","disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.falta),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "falta", $$v)},expression:"adminGabinete.falta"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('h6',{staticClass:"text-primary"},[_vm._v(" Solicitud de Medidor ")]),_c('validation-provider',{attrs:{"name":"Solicitud de Medidor","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"no-resize":"","disabled":_vm.editable,"state":errors.length > 0 ? false:null},model:{value:(_vm.adminGabinete.solicitud),callback:function ($$v) {_vm.$set(_vm.adminGabinete, "solicitud", $$v)},expression:"adminGabinete.solicitud"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[(_vm.editable)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.changeEditable}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Editar")])],1)],1):_vm._e(),(!_vm.editable)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2 mr-1",attrs:{"variant":"primary"},on:{"click":_vm.validationForm}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.getTextButton()))])],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-2",attrs:{"variant":"outline-secondary","type":"reset"},on:{"click":function($event){$event.preventDefault();return _vm.cancel.apply(null, arguments)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SlashIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Cancelar")])],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }