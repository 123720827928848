<template>
  <div>
    <b-overlay
      :show="show"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-card>
            <b-row>
              <b-col cols="12">
                <h4
                  v-if="editable"
                  class="text-primary"
                >
                  Información de Centro de Mando P&M
                </h4>
                <h4
                  v-if="!editable"
                  class="text-primary"
                >
                  Formulario de Administrador Centros de Mando P&M
                </h4>
                <hr>
                <br>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <h6 class="text-primary">
                    Poste
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Poste"
                    rules="required"
                  >
                    <b-form-input
                      v-model="adminGabinete.poste"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="7">
                <b-form-group>
                  <h6 class="text-primary">
                    Dirección
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Dirección"
                    rules="required"
                  >
                    <b-form-input
                      v-model="adminGabinete.direccion"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-form-group>
                  <h6 class="text-primary">
                    Zona
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Zona"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.zona"
                      :disabled="editable"
                      :options="zonas"
                      label="nombre"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <h6 class="text-primary">
                    Ubicación
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Ubicación"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="adminGabinete.ubicacion"
                      no-resize
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <h6 class="text-primary">
                    Cantidad de Reflectores
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Cantidad de Reflectores"
                    rules="required"
                  >
                    <b-form-input
                      v-model="adminGabinete.reflectores"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <h6 class="text-primary">
                    Flip-pon
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Flip-pon"
                    rules="required"
                  >
                    <b-form-input
                      v-model="adminGabinete.flippon"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <h6 class="text-primary">
                    Cantidad de Borneras
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Cantidad de Borneras"
                    rules="required"
                  >
                    <b-form-input
                      v-model="adminGabinete.borneras"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <h6 class="text-primary">
                    Luz Piloto
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Luz Piloto"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.luzPiloto"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesSiNoYa"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="3">
                <b-form-group>
                  <h6 class="text-primary">
                    Protección Transitoria
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Protección Transitoria"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.transitoria"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesSiNoYa"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4">
                <b-form-group>
                  <h6 class="text-primary">
                    Horario Timer
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Horario Timer"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.horaTimer"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesTimer"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1"
                :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Inicio del Timer
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Inicio del Timer"
                    rules="required"
                  >
                    <b-form-timepicker
                      v-model="adminGabinete.inicioTimer"
                      placeholder="Inicio del Timer"
                      label-no-time-selected="Inicio del Timer"
                      close-button-variant="primary"
                      label-close-button="Aceptar"
                      locale="es"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1"
                :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4"
              >
                <b-form-group>
                  <h6 class="text-primary">
                    Fin del Timer
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Fin del Timer"
                    rules="required"
                  >
                    <b-form-timepicker
                      v-model="adminGabinete.finTimer"
                      placeholder="Fin del Timer"
                      label-no-time-selected="Fin del Timer"
                      close-button-variant="primary"
                      label-close-button="Aceptar"
                      locale="es"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4">
                <b-form-group>
                  <h6 class="text-primary">
                    Candado
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Candado"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.candado"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesCandado"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4">
                <b-form-group>
                  <h6 class="text-primary">
                    Caja Socket
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Caja Socket"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.socket"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesSiNo"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4">
                <b-form-group>
                  <h6 class="text-primary">
                    Contador
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Contador"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.contador"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesSiNo"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4">
                <b-form-group>
                  <h6 class="text-primary">
                    Tierra Física
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Tierra Física"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.tierraFisica"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesSiNo"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col :cols="adminGabinete.horaTimer && adminGabinete.horaTimer.id === 1 ? 3:4">
                <b-form-group>
                  <h6 class="text-primary">
                    Sticker
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Sticker"
                    rules="required"
                  >
                    <v-select
                      v-model="adminGabinete.sticker"
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                      :options="opcionesSiNo"
                      label="nombre"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group>
                  <h6 class="text-primary">
                    Observaciones
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Observaciones"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="adminGabinete.observaciones"
                      no-resize
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <h6 class="text-primary">
                    Falta
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Falta"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="adminGabinete.falta"
                      no-resize
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                  <h6 class="text-primary">
                    Solicitud de Medidor
                  </h6>
                  <validation-provider
                    #default="{ errors }"
                    name="Solicitud de Medidor"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="adminGabinete.solicitud"
                      no-resize
                      :disabled="editable"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- BOTONES -->
              <b-col
                v-if="editable"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="changeEditable"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Editar</span>
                </b-button>
              </b-col>
              <b-col
                v-if="!editable"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="validationForm"
                >
                  <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ getTextButton() }}</span>
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="cancel"
                >
                  <feather-icon
                    icon="SlashIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormInput, BButton, BForm, BFormGroup, BFormTextarea, BFormTimepicker, BOverlay,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { getAdminGabinete, updateCreateAdminGabinete } from '@/utils/administrador/gabinetes'
import { getListadoZonas } from '@/utils/localizacion'
import { getOpciones } from '@/utils/mantenimiento/opcionesGenericas'

export default {
  components: {
    BForm,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormTextarea,
    BFormTimepicker,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      required,
      usuario: JSON.parse(localStorage.getItem('userData')),
      adminGabinete: {
        poste: null,
        zona: '',
        reflectores: null,
        flippon: null,
        luzPiloto: null,
        horaTimer: null,
        inicioTimer: '',
        finTimer: '',
        transitoria: null,
        borneras: null,
        candado: null,
        socket: null,
        contador: null,
        tierraFisica: null,
        sticker: null,
        observaciones: null,
        falta: null,
        solicitud: null,
        ubicacion: null,
        direccion: null,
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
      },
      visibility: router.currentRoute.params.action !== 'Create',
      editable:
          router.currentRoute.params.action !== 'Edit'
          && router.currentRoute.params.action !== 'Create',
      opcionesSiNo: [],
      opcionesSiNoYa: [],
      opcionesTimer: [],
      opcionesCandado: [],
      zonas: [],
    }
  },
  async created() {
    await this.loadAdminGabinete()
    this.checkAccessMode()
    this.zonas = getListadoZonas(1)
    this.opcionesSiNo = getOpciones(1)
    this.opcionesSiNoYa = getOpciones(2)
    this.opcionesTimer = getOpciones(3)
    this.opcionesCandado = getOpciones(4)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    async loadAdminGabinete() {
      const { id } = this.$route.query
      if (id) {
        this.adminGabinete = await getAdminGabinete(id)
        if (!this.adminGabinete) {
          console.error('Elemento no encontrado')
        }
      }
    },
    async guardarAdminGabinetes() {
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.adminGabinete.usuarioCreacion = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      const body = { ...this.adminGabinete }
      body.zona = this.adminGabinete.zona.value

      if (this.adminGabinete.inicioTimer instanceof Date) {
        this.adminGabinete.inicioTimer = this.adminGabinete.inicioTimer.toTimeString().slice(0, 5)
      }

      if (this.adminGabinete.finTimer instanceof Date) {
        this.adminGabinete.finTimer = this.adminGabinete.finTimer.toTimeString().slice(0, 5)
      }
      // eslint-disable-next-line no-unused-vars
      let item

      if (this.$route.query.action === 'Create') {
        item = await updateCreateAdminGabinete(body, 2)
      } else if (this.$route.query.action === 'Edit') {
        item = await updateCreateAdminGabinete(body, 1)
      }
      mensajeInformativo('Guardada', 'Centro de Mando P&M Almacenado Correctamente!')
      this.$router.replace('/administrador-gabinetes').then(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Administración de Centro de Mando P&M Almacenada!',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Administración de Centro de Mando P&M Ingresada Correctamente!',
          },
        })
      })
      this.show = false
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.guardarAdminGabinetes()
        }
      })
    },
    checkAccessMode() {
      const { action } = this.$route.query
      this.editable = action === 'Edit'

      if (!this.editable) {
        this.hideAdditionalColumn()
      }
    },
    hideAdditionalColumn() {
      this.showAdditionalColumn = false
    },
    changeEditable() {
      router.currentRoute.params.action = 'Edit'
      this.editable = false
    },
    getTextButton() {
      if (router.currentRoute.params.action === 'Edit') return 'Actualizar'
      return 'Guardar'
    },
    cancel() {
      const { action } = this.$route.query
      if (action === 'Edit' || action === 'Create'
      ) this.$router.push({ path: '/administrador-gabinetes' })
    },
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
